.page-header {
  z-index: 21;
  width: calc(100% - 2 * var(--page-header-spacing));
  padding: var(--page-header-spacing) 0px;
  margin: 0px var(--page-header-spacing);
  box-sizing: border-box;
  display: grid;
  grid-template: 64px / 1fr max-content;
  grid-gap: 24px;
  align-items: center;
  border-bottom: 1px solid var(--Gray400);

  .back-icon {
    flex-shrink: 0;
    margin-left: -16px;
    cursor: pointer;
  }

  .header-title {
    height: 64px;
    font-size: 52px;
    color: var(--Gray900);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .buttons-wrapper {
    height: 42px;
    display: flex;
  }
}
