.club-member-detail {
  --detail-width: 300px;

  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  display: grid;
  grid-template: 1fr / var(--detail-width) 1fr;
  grid-gap: 24px;
  overflow: scroll;

  .club-member-details {
    position: relative;
    z-index: 1;
    height: calc(var(--content-inner-height) - 2 * var(--content-padding));
    width: var(--detail-width);
    padding: 24px;
    box-sizing: border-box;
    color: var(--Gray900);
    background-color: var(--White);
    border-radius: 8px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;

    &.loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .inactive-tag {
      position: absolute;
      top: 0;
      left: 0;
      width: 118.8px; // 84sqrt(2)
      height: 84px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      background-color: var(--primary-color);
      transform: rotate(-45deg);
      transform-origin: 0 100%;

      .inactive-label {
        margin-bottom: 4px;
        color: var(--White);
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }

    .club-member-user {
      height: 144px;
      width: 144px;
      margin: 0px auto 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 4px solid var(--Green);
      border-radius: 50%;

      .club-member-user-profile {
        height: 134px;
        width: 134px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--Gray200);
        border-radius: 50%;

        * {
          stroke-width: 1 !important;
        }
      }
    }

    .club-member-name {
      margin-bottom: 8px;
      color: var(--Gray900);
      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }

    .club-member-info-row {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .info-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title-line {
          font-size: 14px;
          font-weight: 500;
        }
        .detail-line {
          color: var(--secondary-color);
          font-size: 12px;
          font-weight: 600;
        }
      }

      .divider {
        align-self: stretch;
        width: 1px;
        background-color: var(--Gray200);
      }
    }

    .mobile-usage-row {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon-wrapper {
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary-color);
        border-radius: 50%;
      }

      .details-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .details-title {
          color: var(--Gray400);
          font-size: 10px;
        }

        .details-text {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .info-row {
      margin: 0px -24px;
      margin-bottom: 12px;
      padding: 0px 24px;
      padding-top: 12px;
      border-top: 1px solid var(--Gray200);

      &.single {
        display: flex;
        align-items: center;

        .info-title {
          margin-bottom: 0px;
          margin-right: 8px;
        }

        .info-text {
          flex-grow: 1;
        }
      }

      &.clickable {
        cursor: pointer;
      }

      .info-title {
        margin-bottom: 2px;
        color: var(--Gray400);
        font-size: 11px;
      }

      .info-text {
        font-size: 12px;
        font-weight: 500;

        .info-text-checkbox-wrapper {
          width: 100%;
          margin-bottom: -4px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .club-member-pages-wrapper {
    --tab-bar-height: 71px;
    --content-width: calc(var(--content-inner-width) - var(--detail-width) - var(--content-padding));

    width: var(--content-width);
    min-width: 760px;
    display: flex;
    flex-direction: column;

    .club-member-tab-bar-wrapper {
      flex: 0;
      width: 100%;
    }

    .tab-detail {
      flex-grow: 1;
      width: 100%;
      // margin-bottom: var(--content-padding);
    }
  }
}

.club-member-loading-container {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
