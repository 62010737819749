.pledges {
  --gauge-width: calc(var(--content-width) * 0.35);

  height: calc(
    var(--content-inner-height) - var(--tab-bar-height) - 2 *
      var(--content-padding)
  );
  width: var(--content-width);
  padding-top: var(--content-padding);
  display: grid;
  grid-template: var(--gauge-width) 1fr / var(--gauge-width) 1fr;
  grid-gap: var(--content-padding);

  .progress-gauge {
    height: var(--gauge-width);
    width: var(--gauge-width);
    background-color: var(--White);
    border-radius: 8px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12),
      0 2px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    box-sizing: border-box;
  }

  .history-table {
    height: var(--gauge-width);
    background-color: var(--White);
    border-radius: 8px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12),
      0 2px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    box-sizing: border-box;
  }
}
