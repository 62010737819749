.personal-container {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;
  box-sizing: border-box;

  .personal-inputs {
    display: flex;
    flex-direction: column;

    .picture {
      width: 200px;
      margin-bottom: 24px;
    }
    .email-container {
      margin-bottom: 16px;
      .email {
        padding: 16px;
      }
    }
    .name-container {
      width: 650px;
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;

      .first-name {
        width: 300px;
      }
      .last-name {
        width: 300px;
        margin-left: 16px;
      }
    }

    .phone {
      width: 300px;
    }
  }
}
