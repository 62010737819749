.pledge-progress-gauge {
  --Green: #29c4b2;

  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: var(--content-padding);
  padding: calc(var(--content-padding) * 2 / 3) var(--content-padding);
  box-sizing: border-box;

  .chart-title {
    font-size: 14px;
    line-height: 16px;
  }

  .chart-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 2 * var(--content-padding));

    svg {
      --gauge-height-base: 160;
      --gauge-width-base: 210;

      width: 100%;
      padding-top: calc(
        1% * var(--gauge-height-base) / var(--gauge-width-base)
      );
    }
  }

  .info-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 2 * var(--content-padding));
    margin-top: var(--content-padding);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon-wrapper {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;

      .summary-icon {
        height: 20px;
        width: 20px;
        color: #fff;
      }
    }

    .value {
      margin-top: 20px;
      font-size: 36px;
      font-weight: 500;
      letter-spacing: -2px;
    }

    .title {
      margin-top: 12px;
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
    }

    .subtitles {
      position: absolute;
      width: var(--gauge-width);
      top: calc(100% + 12px);

      .subtitle {
        margin-top: -2px;
        color: var(--Gray500);
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;

        .parameter {
          font-weight: 600;
        }
      }
    }
  }
}
