.benefits {
  height: 100%;
  width: var(--content-width);
  padding: var(--content-padding);
  box-sizing: border-box;

  .tickets-section-title {
    height: 18px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .member-benefits-container {
    position: relative;
    width: var(--content-width);

    .member-benefits-wrapper {
      width: calc(var(--content-width) + calc(2 * var(--content-padding)));
      margin: 0px calc(-2 * var(--content-padding)) var(--content-padding);
      padding: var(--content-padding) calc(2 * var(--content-padding));
      box-sizing: border-box;
      overflow-x: scroll;

      .member-benefits {
        --benefit-height: 475px;
        width: 100%;
        display: grid;
        grid-template: auto 1fr / repeat(auto-fit, minmax(324px, 1fr));
        grid-gap: var(--content-padding);

        .benefit-card {
          flex-shrink: 0;
          display: inline-block;

          .benefit-inner {
            // height: 100%;
            width: 100%;
            padding: var(--content-padding);
            background-color: var(--White);
            border-radius: 8px;
            box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;

            .icon-wrapper {
              height: 40px;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: var(--primary-color);
              border-radius: 50%;
            }

            .sport-title {
              margin-top: 18px;
              font-size: 18px;
              font-weight: 500;
            }

            .benefit-ticket-quantity {
              font-size: 56px;
              font-weight: 500;
              line-height: 72px;
            }

            .benefit-ticket-quantity-label {
              color: var(--Gray500);
            }

            .benefit-details-container {
              margin-top: 18px;
              width: 100%;
              display: flex;
              justify-content: space-around;

              .benefit-detail {
                display: flex;
                flex-direction: column;
                align-items: center;

                .detail-label {
                  color: var(--Gray500);
                  font-size: 12px;
                }

                .detail-value {
                  font-weight: 500;
                }
              }
            }

            .benefit-extras {
              margin-top: 24px;
              width: 100%;

              .benefit-extras-title {
                margin-bottom: 8px;
                color: var(--Gray500);
                font-size: 12px;
              }

              .benefit-extra {
                display: flex;
                font-weight: 500;
                line-height: 24px;

                .extra-bullet {
                  margin-right: 12px;
                }
              }
            }
          }
        }
      }
    }

    .fade {
      position: absolute;
      top: 0;
      left: calc(-2 * var(--content-padding));
      height: 100%;
      width: calc(2 * var(--content-padding));
      background: linear-gradient(90deg, var(--Gray50) 0%, var(--Gray50) 60%, rgba(250, 250, 250, 0) 100%);
    }
  }
}
