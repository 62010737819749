.contributions-table-container {
  --summary-numbers-height: 156px;
  --title-height: 48px;

  --container-height: calc(
    var(--content-inner-height) - 4 * var(--content-padding) - var(--tab-bar-height) - var(--summary-numbers-height)
  );

  height: var(--container-height);
  display: flex;
  flex-direction: column;

  .contributions-table-title {
    flex-shrink: 0;
    height: var(--title-height);
    padding: 0px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-text {
      font-size: 14px;
    }
  }

  .spinner-container {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ka {
    --header-row-height: 48px;
    box-shadow: none;
    border-radius: 0px;

    .ka-tbody {
      max-height: calc(var(--container-height) - var(--title-height) - var(--header-row-height));
    }
  }
}
