.pledge-history-table-container {
  --title-height: 48px;

  height: var(--gauge-width);

  .pledge-history-table-title {
    height: var(--title-height);
    padding: 0px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-text {
      font-size: 14px;
    }
  }

  .ka {
    --header-row-height: 48px;
    box-shadow: none;
    border-radius: 0px;

    .ka-tbody {
      max-height: calc(
        var(--gauge-width) - var(--title-height) - var(--header-row-height)
      );

      .ka-cell .amount-cell {
        &.green {
          color: var(--Green);
        }

        &.red {
          color: var(--Red);
        }
      }
    }
  }
}
