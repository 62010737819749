.tickets {
  height: 100%;
  width: var(--content-width);
  padding: var(--content-padding);
  box-sizing: border-box;

  .tickets-section-title {
    height: 18px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .season-tickets-container {
    position: relative;
    width: var(--content-width);

    .season-tickets-wrapper {
      width: calc(var(--content-width) + calc(2 * var(--content-padding)));
      margin: 0px calc(-2 * var(--content-padding)) var(--content-padding);
      padding: var(--content-padding) calc(2 * var(--content-padding));
      box-sizing: border-box;
      overflow-x: scroll;

      .season-tickets {
        --ticket-height: 225px;
        width: 100%;
        display: grid;
        grid-template: repeat(auto-fit, var(--ticket-height)) / repeat(auto-fit, minmax(350px, 1fr));
        grid-gap: var(--content-padding);

        .ticket-card {
          flex-shrink: 0;
          height: var(--ticket-height);
          // width: 425px;
          padding: 0px var(--content-padding);
          display: inline-block;
          background-color: var(--White);
          border-radius: 8px;
          box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
          white-space: nowrap;

          .ticket-inner {
            height: 100%;
            width: 100%;
            display: flex;

            .ticket-banner {
              height: 100%;
              width: calc(var(--ticket-height) * 85 / 200);
              margin-right: var(--content-padding);
              display: flex;
              background-color: var(--primary-color);

              .banner-stripe {
                height: 100%;
                width: calc(var(--ticket-height) * 4 / 200);
                background-color: var(--secondary-color);
              }

              .banner-body {
                --banner-width: calc(var(--ticket-height) * 77 / 200);

                height: 100%;
                width: var(--banner-width);
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--primary-color);

                .banner-contents {
                  display: flex;
                  justify-content: space-evenly;
                  align-items: center;
                  transform: rotate(90deg);

                  .school-logo {
                    width: calc(0.6 * var(--banner-width));
                    height: auto;
                  }

                  .season-label {
                    margin-left: var(--content-padding);
                    color: var(--White);
                    text-transform: uppercase;
                  }
                }
              }
            }

            .ticket-details {
              flex-grow: 1;
              height: 100%;
              padding: var(--content-padding) 0px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;

              .ticket-sport {
                font-size: 18px;
                font-weight: 500;
              }

              .ticket-quantities {
                margin-top: -12px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .ticket-quantity {
                  font-size: 56px;
                  font-weight: 500;
                }

                .ticket-quantity-label {
                  color: var(--Gray500);
                }
              }

              .ticket-extras {
                width: 100%;
                display: flex;
                justify-content: space-around;

                .ticket-extra {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  .extra-label {
                    color: var(--Gray500);
                    font-size: 12px;
                  }

                  .extra-value {
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }

    .fade {
      position: absolute;
      top: 0;
      left: calc(-2 * var(--content-padding));
      height: 100%;
      width: calc(2 * var(--content-padding));
      background: linear-gradient(90deg, var(--Gray50) 0%, var(--Gray50) 60%, rgba(250, 250, 250, 0) 100%);
    }
  }

  .member-benefits-container {
    position: relative;
    width: var(--content-width);

    .member-benefits-wrapper {
      width: calc(var(--content-width) + calc(2 * var(--content-padding)));
      margin: 0px calc(-2 * var(--content-padding)) var(--content-padding);
      padding: var(--content-padding) calc(2 * var(--content-padding));
      box-sizing: border-box;
      overflow-x: scroll;

      .member-benefits {
        position: relative;
        width: 100%;
        white-space: nowrap;

        .benefit-card {
          flex-shrink: 0;
          height: 475px;
          width: 324px;
          margin-right: var(--content-padding);
          display: inline-block;
          background-color: var(--White);
          border-radius: 8px;
          box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);

          .benefit-inner {
            height: 100%;
            width: 100%;
            padding: var(--content-padding);
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;

            .icon-wrapper {
              height: 40px;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: var(--primary-color);
              border-radius: 50%;
            }

            .sport-title {
              margin-top: 18px;
              font-size: 18px;
              font-weight: 500;
            }

            .benefit-ticket-quantity {
              font-size: 56px;
              font-weight: 500;
              line-height: 72px;
            }

            .benefit-ticket-quantity-label {
              color: var(--Gray500);
            }

            .benefit-details-container {
              margin-top: 18px;
              width: 100%;
              display: flex;
              justify-content: space-around;

              .benefit-detail {
                display: flex;
                flex-direction: column;
                align-items: center;

                .detail-label {
                  color: var(--Gray500);
                  font-size: 12px;
                }

                .detail-value {
                  font-weight: 500;
                }
              }
            }

            .benefit-extras {
              margin-top: 24px;
              width: 100%;

              .benefit-extras-title {
                margin-bottom: 8px;
                color: var(--Gray500);
                font-size: 12px;
              }

              .benefit-extra {
                font-weight: 500;
                line-height: 24px;
              }
            }
          }
        }
      }
    }

    .fade {
      position: absolute;
      top: 0;
      left: calc(-2 * var(--content-padding));
      height: 100%;
      width: calc(2 * var(--content-padding));
      background: linear-gradient(90deg, var(--Gray50) 0%, var(--Gray50) 60%, rgba(250, 250, 250, 0) 100%);
    }
  }
}
