.contributions {
  --summary-number-height: 156px;

  flex-grow: 0;
  height: calc(var(--content-inner-height) - var(--tab-bar-height) - 2 * var(--content-padding));
  width: var(--content-width);

  .summary-numbers {
    height: var(--summary-number-height);
    width: 100%;
    margin: var(--content-padding) 0px;
    display: grid;
    grid-template: 1fr / repeat(4, 1fr);
    grid-gap: var(--content-padding);

    .summary-number {
      height: var(--summary-number-height);
      padding: 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;

      .icon-wrapper {
        background-color: var(--primary-color);
      }

      .icon-wrapper {
        --wrapper-size: 32px;
        height: var(--wrapper-size);
        width: var(--wrapper-size);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        .summary-icon {
          height: 16px;
          width: 16px;
          color: #fff;
        }
      }

      .value {
        text-align: center;
        font-size: 32px;
        font-weight: 500;
        letter-spacing: -0.5px;
      }

      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }

      .indicator {
        margin-top: -8px;
        color: var(--secondary-color);
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;

        &.empty {
          color: var(--White);
          user-select: none;
        }
      }
    }
  }

  .metrics {
    height: calc(100% - var(--summary-number-height) - 2 * var(--content-padding));
    display: grid;
    grid-template: 1fr / 3fr 1fr;
    grid-gap: var(--content-padding);

    .contributions-table {
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      background-color: var(--White);
      border-radius: 8px;
      box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
      overflow: hidden;
    }
  }
}
